@import '../shared/styles/colours.scss';
@import '../shared/styles/mixins.scss';

#contact {
    background-image: url('/contact-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100% - 69px);
    padding-top: 2rem;

    #contact-header {
        @include text;
        padding-top: 5rem;
        font-size: 4rem;
        font-weight: normal;
    }

    #contact-details {
        display: block;
        margin-top: 2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-size: 1.6rem;

        > div {
            padding-bottom: 0.5rem;
            white-space: nowrap;
            overflow: visible;

            svg {
                margin-right: 0.5rem;
            }
        }
    }

    #contact-form-header {
        @include text;
        font-size: 3rem;
    }

    form {
        display: inline-block;
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 2rem;

        .form-group {
            margin-bottom: 1rem;
        }

        .form-validation {
            color: red;
        }

        #contact-success, #contact-failure {
            font-weight: bold;
            text-shadow: 1px 1px 1px black;
            margin-left: 1rem;
        }

        #contact-success {
            color: white;
        }

        #contact-failure {
            color: red;
        }

        .btn {
            background-color: $primary-color;
            border-color: $primary-color;
            font-weight: bold;
            transition: ease-in-out background-color .15s;

            &:hover {
                background-color: darken($primary-color, 15%);
                border-color: darken($primary-color, 15%);
            }
        }
    }

    > .row {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: 1200px) {
    #contact {      
        padding-top: 0;

        #contact-header {
            font-size: 3rem;
        }

        #contact-details {
            > div {
                word-wrap: break-word;
                white-space: unset;
                overflow: unset;
            }
        }
    
        #contact-form-header {
            font-size: 2rem;
        }
    }
}

@media (max-width: 767px) {
    #contact {
        #contact-header {
            font-size: 2rem;
        }

        #contact-details {
            > div {
                font-size: 1.2rem;
            }
        }
        
        form {
            .btn {
                width: 100%;
            }
        }
    }
}