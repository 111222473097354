@import '~bootstrap/scss/bootstrap.scss';

@import './shared/styles/colours.scss';
@import './shared/styles/mixins.scss';

html, body, #root, .page {
    height: 100%;
}

.page {
    padding-top: 80px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn {
    border-radius: 0;
    padding: 0.75rem 2rem;
}

form .form-control {
    height: auto;
    border-radius: 0;
    padding: 0.75rem 1rem;

    &:focus {
        box-shadow: none;
    }
}

.image-text-container {
    display: inline-block;
    background: rgba(rgb(51,63, 80), .5);

    > * {
        @include text;
        padding: .5rem 1.5rem 1.5rem 1.5rem;
        line-height: 1.2;
    }
}

a.no-link {
    color: inherit;

    &:hover {
        color: inherit;
        text-decoration: none;
    }
}