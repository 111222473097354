@import '../shared/styles/colours.scss';

#scroll-top {
    position: fixed;
    bottom: 0;
    right: 1rem;
    font-size: 3rem;
    color: $primary-color;
    cursor: pointer;
    transition: ease-in-out color .15s;

    &:hover {
        color: rgb(140, 215, 223);
    }
}